import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { IconDirective } from '@core/shared/util';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SupplierBasic, SupplierCategory, SupplierType } from '@mp/supplier-management/common/domain';

import { SupplierIconNamePipe } from './supplier-icon-name.pipe';

export type SupplierCategoryKey = keyof typeof SupplierCategory | 'ClinicSupplier';

@Component({
  selector: 'mpsm-supplier-icon',
  standalone: true,
  templateUrl: './supplier-icon.component.html',
  styleUrls: ['./supplier-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MatIconModule, SupplierIconNamePipe, IconDirective],
})
export class SupplierIconComponent {
  @Input() set supplier(supplier: SupplierBasic) {
    this.supplierCategoryKey = this.getSupplierIcon(supplier);
  }

  supplierCategoryKey: SupplierCategoryKey | null = null;

  private getSupplierIcon({ type, category }: SupplierBasic): SupplierCategoryKey | null {
    if (type === SupplierType.ClinicSupplier) {
      return 'ClinicSupplier';
    }

    if (category) {
      return SupplierCategory[category] as SupplierCategoryKey;
    }

    return null;
  }
}
