import { Pipe, PipeTransform } from '@angular/core';

import { SupplierCategoryKey } from './supplier-icon.component';

@Pipe({
  name: 'mpsmSupplierIconName',
  standalone: true,
})
export class SupplierIconNamePipe implements PipeTransform {
  transform(supplierCategoryKey: SupplierCategoryKey | null): string {
    switch (supplierCategoryKey) {
      case 'ClinicSupplier':
        return 'maps_home_work';
      case 'Division':
        return '@division';
      case 'Manufacturer':
        return 'factory';
      case 'ParentOrganization':
        return 'foundation';
      case 'Supplier':
        return 'local_shipping';
      default:
        return 'question_mark';
    }
  }
}
