<div class="icon-container">
  <div class="icon-container__icon-wrapper">
    <mat-icon svgIcon="order" class="icon-container__background-icon" />

    <mat-icon
      class="icon-container__icon material-icons-outlined"
      [ngClass]="{
        'icon-container__icon--clinic-supplier': supplierCategoryKey === 'ClinicSupplier',
        'icon-container__icon--division': supplierCategoryKey === 'Division'
      }"
      [mpIcon]="supplierCategoryKey | mpsmSupplierIconName"
    />
  </div>
</div>
